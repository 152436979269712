import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TextField, LinearProgress } from '@material-ui/core';

import { useTitleStyles } from './FeatureComponent';
import { TableDataContext } from '../../tabeditor/tools/Table';

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(() => ({
  dateTimeEditor: {
    maxWidth: '25rem',
    width: '19rem',
    '& > .MuiInputBase-root': {
      height: '44px'
    }
  },
  dateTimeEditorInTable: {
    maxWidth: '25rem',
    width: '19rem',
    '& > .MuiInputBase-root': {
      height: '44px'
    }
  }
}));

export const getDateTimePayloadData = (value) => ({
  dateValue: { year: value.year, month: value.month, day: value.day },
  timeValue: { hour: value.hour, minute: value.minute }
});

export const DateTime = ({
  featureInfo,
  dateTime,
  setValueState,
  saveChangedValue,
  loading,
  error
}) => {
  const { t } = useTranslation();
  const [isInTableContext] = useContext(TableDataContext);
  const classesTitle = useTitleStyles();

  const classes = useStyles();

  const title = isInTableContext ? null : featureInfo.title;
  const mandatory = featureInfo.mandatory;

  const hasDefaultData = featureInfo.hasDefaultData;

  let dateTimeString;
  if (
    dateTime &&
    dateTime.year &&
    dateTime.month &&
    dateTime.day &&
    dateTime.hour != null &&
    dateTime.minute != null
  ) {
    dateTimeString =
      dateTime.year +
      '-' +
      String(dateTime.month).padStart(2, '0') +
      '-' +
      String(dateTime.day).padStart(2, '0') +
      'T' +
      String(dateTime.hour).padStart(2, '0') +
      ':' +
      String(dateTime.minute).padStart(2, '0');
  } else {
    dateTimeString = '';
  }

  const [blurState, setBlurState] = useState(dateTimeString);

  const isMandatoryError = mandatory && dateTimeString == '';
  error = error || '';
  let helper = '';
  if (loading) {
    helper = <LinearProgress color="secondary" />;
  } else if (isMandatoryError && !hasDefaultData) {
    helper = t('common.errorMandatory');
  }

  function saveValue(newDateTimeString) {
    if (blurState == newDateTimeString || loading) return;
    setBlurState(newDateTimeString);

    let newValue = null;

    if (newDateTimeString) {
      newValue = getDateTimeValueObject(newDateTimeString);
    } else if (mandatory && !hasDefaultData) {
      return;
    }

    setValueState(newValue);
    saveChangedValue(newValue, getDateTimePayloadData(newValue));
  }

  function getDateTimeValueObject(newDateTimeString) {
    const dateTimeSplitted = newDateTimeString.split('T');
    const newDateString = dateTimeSplitted[0];
    const newTimeString = dateTimeSplitted[1];

    const dateSplitted = newDateString.split('-');
    const timeSplitted = newTimeString.split(':');

    return {
      year: dateSplitted[0],
      month: dateSplitted[1],
      day: dateSplitted[2],
      hour: timeSplitted[0],
      minute: timeSplitted[1]
    };
  }

  function showError() {
    if (!error) return;
    TOGO.Util.notifyResponse(error, true);
  }

  return (
    <TextField
      label={title}
      InputLabelProps={{
        shrink: false,
        disableAnimation: true,
        className: classesTitle.title
      }}
      className={
        isInTableContext
          ? classes.dateTimeEditorInTable
          : classes.dateTimeEditor
      }
      variant={isInTableContext ? 'standard' : 'outlined'}
      value={dateTimeString}
      type="datetime-local"
      error={Boolean(error) || (isMandatoryError && !hasDefaultData)}
      FormHelperTextProps={{ component: 'div' }}
      helperText={helper}
      onMouseEnter={showError}
      onChange={(event) => saveValue(event.target.value)}
    />
  );
};
