import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Alphanumeric } from './Alphanumeric';
import { Numeric } from './Numeric';
import { Bool } from './Bool';
import { Date } from './Date';
import { Selection } from './Selection';
import { Range } from './Range';
import { ReadOnlyFeature } from './ReadOnlyFeature';
import { ToggleStandardValues } from './ToggleStandardValues';
import { Time } from './Time';
import { DateTime } from './DateTime';

export const useTitleStyles = makeStyles(() => ({
  title: {
    top: '-2rem',
    left: '-1rem',
    position: 'relative',
    fontSize: '1.5rem !important',
    fontFamily: "'Roboto', sans-serif !important",
    color: '#636363 !important'
  },
  titleSelect: {
    top: '-1rem',
    left: '-1rem',
    position: 'relative',
    fontSize: '1.5rem !important',
    fontFamily: "'Roboto', sans-serif !important",
    color: '#636363 !important'
  },
  datepickerStyle: {
    maxWidth: '25rem',
    width: '250px',
    '& > .MuiInputBase-root': {
      height: '44px'
    }
  },
  booleanInputStyle: {
    maxWidth: '25rem',
    width: '250px',
    '& > .MuiInputBase-root': {
      height: '33px'
    }
  },
  input__: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));

export function FeatureComponent({
  featureInfo,
  value,
  setValueState,
  saveChangedValue,
  loading,
  error,
  setBlockDefaultData,
  resetBlockDefaultData,
  isBlockDefaultData
}) {
  if (featureInfo?.readOnlyFeature || featureInfo?.isDerivedFeature) {
    return (
      <ReadOnlyFeature
        featureInfo={featureInfo}
        featureValue={value}
        loading={loading}
        error={error}
      />
    );
  }

  const featureType = featureInfo.featureComponent.featureType;

  const getFeatureTypeComponent = () => {
    switch (featureType) {
      case 'FEATURE_TYPE_ALPHANUMERIC':
      case 'FEATURE_TYPE_ALPHANUMERIC_NON_LOCALIZED':
        return (
          <Alphanumeric
            featureInfo={featureInfo}
            text={value}
            setValueState={setValueState}
            saveChangedValue={saveChangedValue}
            loading={loading}
            error={error}
          />
        );
      case 'FEATURE_TYPE_NUMERIC':
        return (
          <Numeric
            featureInfo={featureInfo}
            number={value}
            setValueState={setValueState}
            saveChangedValue={saveChangedValue}
            loading={loading}
            error={error}
          />
        );
      case 'FEATURE_TYPE_BOOLEAN':
        return (
          <Bool
            featureInfo={featureInfo}
            value={value}
            setValueState={setValueState}
            saveChangedValue={saveChangedValue}
            loading={loading}
            error={error}
          />
        );
      case 'FEATURE_TYPE_LOCAL_DATE':
        return (
          <Date
            featureInfo={featureInfo}
            date={value}
            setValueState={setValueState}
            saveChangedValue={saveChangedValue}
            loading={loading}
            error={error}
          />
        );
      case 'FEATURE_TYPE_LOCAL_TIME':
        return (
          <Time
            featureInfo={featureInfo}
            time={value}
            setValueState={setValueState}
            saveChangedValue={saveChangedValue}
            loading={loading}
            error={error}
          />
        );
      case 'FEATURE_TYPE_LOCAL_DATE_TIME':
        return (
          <DateTime
            featureInfo={featureInfo}
            dateTime={value}
            setValueState={setValueState}
            saveChangedValue={saveChangedValue}
            loading={loading}
            error={error}
          />
        );
      case 'FEATURE_TYPE_ALPHANUMERIC_SELECTION':
      case 'FEATURE_TYPE_ALPHANUMERIC_MULTI_SELECTION':
        return (
          <Selection
            featureInfo={featureInfo}
            value={value}
            setValueState={setValueState}
            saveChangedValue={saveChangedValue}
            loading={loading}
            error={error}
          />
        );
      case 'FEATURE_TYPE_NUMERIC_RANGE':
        return (
          <Range
            featureType={featureType}
            featureInfo={featureInfo}
            values={value}
            setValueState={setValueState}
            saveChangedValue={saveChangedValue}
            loading={loading}
            error={error}
          />
        );
    }
  };

  return (
    <div className="defaultColor">
      {getFeatureTypeComponent()}
      {(featureInfo.isDefaultData || isBlockDefaultData) &&
        !featureInfo.isCreateInstance && (
          <ToggleStandardValues
            setBlockDefaultData={setBlockDefaultData}
            resetBlockDefaultData={resetBlockDefaultData}
            isBlockDefaultData={isBlockDefaultData}
            preventResetValue={false}
          />
        )}
    </div>
  );
}
